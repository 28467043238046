
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@1,700&display=swap');
html {
  font-size: 62.5%; /* 16px * 62.5 = 10px = 1rem */
  box-sizing: border-box;
  min-height: 100%;
  position: relative;
  background-color:#13141f;



}


*, *:before, *:after {
  box-sizing: inherit;
}

/*GLOBALES*/
/**FONTS***/

@import url('https://fonts.googleapis.com/css2? family = Roboto: ital @ 1 & display = swap');


  #root {
    height: 100%;
    background-color:#191922;
  }

  body {
    background-color:#d6d2d3;
    text-align: center;
    margin: 0;
    padding: 0;
    font-family: "Raleway",sans-serif;
    font-display: swap;
    /* margin-bottom: 40px; */
    }


   
  .hr{
    
    height: 0;
    border-top: 1px solid #c2bfbf !important;
  }

  
  
    /****titulos y textos*****/
    .bold{
      font-weight: bold;
    }
    .texto-general{
      display:inline-block;
    padding: 1rem;
    text-align: initial;
    line-height: 2.5rem;
    margin-left: 1rem !important;
    
    }
    .color-black{
      color: black;
    }
    .color-white{
      color: white;
    }
    .color-amarillo{
      color: #FFFF77;
    }
    .centrar{
    display: flex;
    align-items: center;
    justify-content: center;
    }
    p{
      font-family: "Raleway",sans-serif;
      font-display: swap;
    }
    h1{
    text-align: initial;
     color:  white;
     font-family: "Raleway",sans-serif;
     font-display: swap;
     font-weight: bold;
     margin-left: 1rem;
     margin-bottom: 1rem !important;
     margin-top: 1.5rem !important;

    }
    .h1{
      font-family: 'Nunito', sans-serif;
      font-display: swap;
      font-weight: bold;
      margin-left: 1rem;
      margin-bottom: 1rem !important;
      margin-top: 3rem !important;
      text-align: initial;
      color:  white;
      letter-spacing: 0.5rem;
      font-size: 2rem !important;
    }
    .datos-table{
      font-family: 'Chakra Petch', sans-serif;
      font-display: swap;
      font-weight: bold;
      margin-left: 1rem;
      text-align: initial;
      color:  white;
      font-size: 1.5rem !important;
    }
    .gol {
      font-size: 4rem;
      font-family: 'Chakra Petch', sans-serif;
      font-display: swap;
      font-weight: bold;
      text-align: initial;
      color:  white;
      letter-spacing: 0.5rem;
    }
    .h1-1{
      font-family: 'Nunito', sans-serif;
      font-display: swap;
      font-weight: bold;
      text-align: initial;
      color:  white;
      letter-spacing: 0.5rem;
      font-size: 2rem !important;
    }
    

    h2{
      text-align: initial;
      color: white;
      font-family: "Raleway",sans-serif;
      font-display: swap;
      /* font-size: 1.5rem !important; */
      font-size:1.8rem !important;
      margin: 1rem !important;
      font-weight: bold !important;
    }
    .p{
      color: white;
      font-family: "Raleway",sans-serif;
      font-display: swap;
      font-size: 1.5rem !important;
      margin-left: 1rem;
    }
    .p2{
      color: white;
      font-family: "Raleway",sans-serif;
      font-display: swap;
      font-size: 1.5rem !important;
      margin-left: 1rem;
    }
    .link-naranja{
      color: #174D03;
      font-family: "Raleway",sans-serif;
      font-display: swap;
      font-size: 1.5rem !important;
      margin-left: 1rem;
      margin-bottom: 0.7rem;
      font-weight: bold !important;
    }

  
    /***BOTONES,SELECT,INPUT****/
    .search{
      display: flex;
      background-color: whitesmoke;
      border-radius: 1rem;
      height: 3.5rem;
      justify-content: center;
      align-items: center;
      padding-left: 1rem;
    }
    .filter {
      display: flex;
      list-style-type: none;
      padding: 0;
      justify-content: space-around;
      align-items: center;
      margin-top: 2.5rem;
      margin-bottom: 2.5rem;
      margin-right: 1rem;
      margin-left: 1rem;
     
    }
    @media (min-width:760px) {
      .filter {
    display: flex;
    list-style-type: none;
    justify-content: left;
    padding: 0;
    align-items: center;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    margin-right: 1rem;
    margin-left: 1rem;
}
.filter select{

  width: 15rem !important;
 
}
.search{
  margin-left: 3rem;
  width: 25rem !important;
}

    }


    .filter-todo{
      font-size: calc(16px + 6 * ((10vw - 320px) / 680));
      background-color: #FF2837;
      border: rgb(196, 184, 184);
      border-radius: 1rem;
      color: white;
      width: 8rem;
      height: 3.5rem;
      margin: 2rem;
      font-family: "Raleway",sans-serif;
      font-display: swap;
      font-weight: bold;

    }
    .filter-1 {
      display: flex;
      list-style-type: none;
      width: 15rem;
      height: 3rem;
      justify-content: space-between;
      align-items: center;
      font-family: "Raleway",sans-serif;
      font-display: swap;
      
     
    }
    .filter input,
.filter button {
  
  border-radius: 1rem;
  border: 0.1rem white;
  font-size: 1.6rem;
  background-color:whitesmoke;
  height: 3.5rem;
  width:15rem;
  font-family: "Raleway",sans-serif;
  font-display: swap;
}
    .filter select {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 1rem;
      border: 0.1rem #FF2837;
      font-size: 1.6rem;
      background-color: #FF2837;
      color:white;
      font-weight: bold;
      height: 3.5rem;
      width:15rem;
      font-family: "Raleway",sans-serif;
      font-display: swap;
    }
    .filter option{
      background-color: whitesmoke;
      color: black;
      font-weight: bold;
      font-family: "Raleway",sans-serif;
      font-display: swap;
    }
    .sesion{
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 1rem;
      font-size: calc(16px + 6 * ((4vw - 320px) / 680));
      background-color:#FFD800;
      border: #FFD800;
      border-radius: 1rem;
      color: black;
      cursor: pointer;
      width:8rem;
      height: 3.8rem;
      margin: 0 auto;
      font-family: "Raleway",sans-serif;
      font-display: swap;
      font-weight: bold;
     
    }
    .botones{
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 2rem;
    }
    .btn-block {
      width: 80% !important;
      margin-left: 1rem !important;
      margin-right: 1rem!important;
  }
  .btn-primary {
    background-color:#174D03 !important;
    border-color: #174D03 !important;
  }
  .btn-primary-2 {
    color: #174D03 !important;
    border-color: #174D03 !important;
    
}
    .centrar-botones{
      display: flex;
      flex-direction: column;

    }
    .botones-img{
      display: flex;
      flex-direction: row;
      justify-content: center;

    }
    .input {
      width: 96%;
      height: 4rem;
      padding: 20px;
      margin-left:0.5rem;
      border: #fff;
      margin-bottom: 20px;
      font-size: 1.5rem;
      font-family: "Raleway",sans-serif;
      font-display: swap;
    }
    ::placeholder{
      font-size: 1.5rem;
    
    }


    .input-cuenta{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 96%;
      height: 4rem;
      padding: 20px;
      margin-left: 0.5rem;
      border: #fff;
      font-size: 1.5rem;
      font-family: "Raleway",sans-serif;
      font-display: swap;
      background-color: #ffffff00;
      color: white;
    
    }
    .input-pago{
      border: #fff;
      width: 8rem;
      font-size: 1.5rem;
      
    }
    .input-cuenta-doc{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      height: 4rem;
      padding: 20px;
      margin-left: 0.5rem;
      border: #fff;
      font-family: "Raleway",sans-serif;
      font-display: swap;
      background-color: rgba(255, 255, 255, 0);
    }
    .input-cuenta2{
      width: 10rem !important;
      margin-left: 2rem;
      margin-bottom: 2rem; 
      border: none;
    }
    .input-cuenta3{
      width: 13rem !important;
      margin-top: 1rem;
      border: 1px solid #ced4da !important;
      height: 4rem !important;
      
      
    }
    .container-f{
      display: flex;
      justify-content:center;
      align-items: center;
      flex-direction: column;
    }
    .container-f2{
      display: flex;
      justify-content:center;
      align-items: center;
      flex-direction: column;
      margin-left: 0!important;
    }
    
    /* .columnas{
      margin-left: 10rem;
    }  */
    @media (min-width:860px){
      .container-f{
        display: flex;
        flex-direction: row;
        align-items: baseline;
      
    }
    .container-f2{
      display: flex;
      flex-direction: row;
      align-items: baseline;
      margin-left: 0!important;
    
  }


 
    .input {
      width: 40rem;
      height: 4rem;
      padding: 20px;
      margin-left:0.5rem;
      border: #fff;
      border-radius: 10px;
      margin-bottom: 20px;
      font-family: "Raleway",sans-serif;
      font-display: swap;
    }


    }

    .label {
      display: flex;
      font-size: 1.5rem !important;
      color: white;
      justify-content: right;
      padding-left: 10px;
      padding-right: 20rem;
      font-family: "Raleway",sans-serif;
      font-display: swap;
    }
    span{
    color: white;
    font-size: 1.7rem;
    }
    .label1{
      display: flex;
      color: white;
      font-weight: bold;
      justify-content: right;
      padding-left: 10px;
      font-family: "Raleway",sans-serif;
      font-display: swap;
      font-size: 1.8rem !important;
    }
    .label112{
      display: flex;
      color: white;
      font-weight: bold;
      justify-content: left;
      padding-left: 10px;
      font-family: "Raleway",sans-serif;
      font-display: swap;
      font-size: 1.8rem !important;
    }
    .select-fecha-inf{
      background-color: rgb(15, 14, 14) !important;
      border: 1px solid #353636 !important;
      font-weight: bold !important;
      color: white !important;

    }
    .encuentro-inf{
      border: solid 1px white;
      padding: 0.5rem;
      margin-bottom: 1rem;
    }
    .comentarios{
      padding: 25px 25px 25px 25px;
      border: white;
      border-radius: 20px;
      margin-bottom: 20px;
      width: 32rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 1rem;
  
    }
    .button-red{
      font-size: calc(16px + 6 * ((10vw - 320px) / 680));
  background-color: #174D03;
  border: rgb(196, 184, 184);
  border-radius: 1rem;
  color: white;
  width: 13rem;
  height: 3.5rem;
  margin: 2rem;
  font-family: "Raleway",sans-serif;
  font-display: swap;
  font-weight: bold;


    }
    /***main-container****/
    .main-container{
      min-height: 100vh;
      background-color:#13141f;
    }
    @media (min-width: 1900px){
      .main-container {
        max-width: 1508px;
        margin: 0 auto 8px;
        padding: 16px 16px 0 16px;
        background-color:#13141f;
    }
  }


  /****FOOTER****/
  .footer{
    background-color:  #13141f;
    /* position: absolute; */
    bottom: 0;
    width: 100%;
    color: white;
    border-top: 1px solid #dee2e6!important;
  }
  @media (min-width:1300px){
    .footer{
      background-color:  #13141f;
      position: relative;
      bottom: 0;
      width: 100%;
      color: white;
      border-top: 1px solid #dee2e6!important;
    }
  }
  .logo-footer{
 
    width: 16rem;
    height: 5rem;

  }
  .footer-logo{
    display: flex;
    justify-content: center;
    margin: 2rem;
   

  }
  .footer-derechos{
    display: flex;
    justify-content: center;
  }
  .footer-redes{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
  .redes-sociales-f{
    margin-right: 3rem;
    height: 2rem;

  }
  .redes-sociales-f-w{
    margin-right: 1rem;
    height: 2rem;

  }

  .separador{
    font-size: 3.5rem;
    margin-right: 2rem;
    margin-bottom: 1rem;
  }
  .whatsapp-c{
    display: flex;
    align-items: center;

  }
 
  /******NAVIGATION******/
  .navigation{
    background-color: #13141f;
  
  }
  .icono-cart{
    display: flex;
    justify-content: center;
    background: none;
    border: none;
    margin-bottom: 1rem;

    
    
  }
  .cart-header{
    background-color: grey;
    color: white;
    border-radius: 100%;
    padding-right: 0.4rem;
    padding-left: 0.4rem;
    margin: 16px;
  }
  .MuiPopover-paper {
    opacity: 1;
    transform: none;
    max-height: 216px;
    width: 200ch !important;
    transition: opacity 287ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 191ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    top: 80px !important ;
    /* max-width: calc(100% - 20px) !important; */

   
    /* left: 24px !important; */
    transform-origin: 71.9844px 31.5px;
  }

  @media (min-width:700px){
    .MuiPopover-paper {
      width: 120ch !important;
      /* max-width: calc(100% - 32px) !important; */
    }
  }
  
  .icon-logo{
    height: 42px;
    width: 100px;
    margin-left: 0.5rem;
}
   
  
  .boton-user{
    height: 3.5rem;
    width: 3.5rem;
    margin-left: 1rem;


  }
  .user-m{
    z-index: 1 !important;
    position:fixed !important;
  }
  .MuiIconButton-root {
    padding: 1px !important;
  }
  .MenuItem{
    height: 1rem;
  }
  .MuiMenuItem-root {
    min-height: 35px !important;
  }
  .MuiListItem-button:hover {
    text-decoration: none;
    background-color: white !important;
}
  .carrito-1{
    width: auto;
    height: 3.2rem;
    border: none;
    background:none; 
  }
  .icono{
    height: 3rem;
    width: 4rem;
    
    background:none;
  
  }
  .icono-button{
    background:none;
  }
  button {
    font-size: .9rem;
    font-weight: 700;
    border: none;
    border-radius: 3px;
    padding: .3rem 1rem;
    margin-left: .5rem;
}
  .MuiIconButton-root{
    border-radius: 0% !important;
  }
  .menu33{
    display: flex;
    width: 98%;
    justify-content: flex-end;
    align-items: center;
  }
  .contenedor-nav{
    display: flex;
    margin: auto;
    width: 98%;
    justify-content: space-between;
    align-items: center;
    height:inherit;
    overflow: hidden;
    z-index:170;
margin:0 auto;
background-color: #13141f;

width:100%;
position:fixed;
top:0;
left:0;
right:0;
text-align: center;
box-shadow: 0 0 15px 0 rgb(0 0 0 / 50%);
  }
  .contenedor-nav2{
    display: flex;
    margin: auto;
    width: 98%;
    
    align-items: center;
    height:inherit;
    overflow: hidden;
    z-index:170;
margin:0 auto;
background-color: #13141f;

width:100%;
position:unset;
top:0;
left:0;
right:0;
text-align: center;
box-shadow: 0 0 15px 0 rgb(0 0 0 / 50%);
  }
  .logo-web{
  margin-left: 4rem;
 
  } 
    
  nav{
    width: 100%;
    box-shadow: 0 0 10px 0 rgba(175, 172, 172, 0.5)
  }
  
  .nav1{
    
    height: 80px;
    color:#fff;
    



  }
  .nav2{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
  }
  .logo-nav{
    display:flex;
    justify-content: center;
    justify-items: center;



  }
  .logo-nav-1{
    display: flex;
    justify-content: center;
    background: none;
    border: none;

  }
  .contenedor-nav-sidebar-22 {
    display: flex;
    width: 90%;
    justify-content: flex-end;
    align-items: center;
    height: inherit;
    overflow: hidden;

}
.cancelar {
  height: 3rem;
  margin-top: 2rem;
}

  .logo-nav-2{
    display: flex;
    justify-content: flex-end;
    align-items: center;

  }

  @media (min-width:799px){
    .nav1{
      display: none;
    }
  }
  @media (max-width:800px){
    .nav2{
      display: none;
    }
  
  }
  /* .lista-navbar{
    display: contents;
    margin: auto;
  } */
  .lista-navbar{
    display: flex;
    margin: auto;
    width: 98%;
    justify-content: flex-end;
    align-items: center;
    height:inherit;
    overflow: hidden;
    z-index:170;
margin:0 auto;
background-color: #13141f;

width:100%;
position:unset;
top:0;
left:0;
right:0;
text-align: center;
/* box-shadow: 0 0 15px 0 rgb(0 0 0 / 50%); */
  }
  .lista-sidebar-n{
    display: -webkit-box;
    justify-content: space-between;
    justify-items: center;
    margin-right: 4rem;
    margin-bottom: 1rem;
    font-size: 1.5rem !important;
    color: white !important;
    font-weight: bold !important;
    font-family: "Raleway",sans-serif;
    font-display: swap;
    

  }
  .navbar-dark .navbar-nav .nav-link{
    color: white !important;
  }

  .lista-sidebar-nsub{
    display: -webkit-box;
    justify-content: space-between;
    justify-items: center;
    margin-right: 5rem;
    margin-bottom: 1rem;
    font-size: 1.2rem;
    color: rgb(0, 0, 0);
    font-weight: bold !important;
    font-family: "Raleway",sans-serif;
    font-display: swap;
    

  }
  .navbar-expand-lg .navbar-collapse{
    justify-content: flex-end !important;
  }
  .menu{
    display: flex
  }
  .container-azul-inf{
    background-color: rgb(98 90 90 / 36%);
    padding-top: 1rem;
    padding-bottom: 5rem;
    width: 80%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 1rem;
    box-shadow: 0 0 10px 0 #FFFFFF;
  }
  .lista-sidebar-n2222{
    display: -webkit-box;
    justify-content: space-between;
    justify-items: center;
    margin-right: 5rem;
    font-size: 1.5rem;
    color: white;
    font-weight: bold;
    font-family: "Raleway",sans-serif;
    font-display: swap;
  }
  .lista-sidebar-ns {
    display: -webkit-box;
    justify-content: space-between;
    justify-items: center;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    color: white;
    font-weight: bold;
    font-family: "Raleway",sans-serif;
    font-display: swap;
}
    /* Sidebar */

      
    
    .sidebar-close-button {
      font-size: 4rem;
      padding: 0.5rem;
      background: none;
      border: none;
      color: #ffffff;
      cursor: pointer;
      display: block;  
    }
    .close {
      position: absolute;
      right: 10px;
      top: 8px;
      width: 32px;
      height: 32px;
      background: none;
      border: none;
  
    }
  
    .close:before, .close:after {
      position: absolute;
      left: 15px;
      content: ' ';
      height: 30px;
      width: 2px;
      background-color: white;
    }
    .close:before {
      transform: rotate(45deg);
      background-color: white;
    }
    .close:after {
      transform: rotate(-45deg);
      background-color: white;
    }
    
  
    .sidebar {
      position: fixed;
      top: 80px;
      background-color: #13141f;
      box-shadow: 0 10px 18px rgb(158 158 158 / 40%), 0 10px 10px rgb(0 0 0 / 22%);
      border: solid 1px;
      border-radius: 0px 10px 10px 10px;
      left: 0;
      height: 70%;
      transition: 1s;
      overflow: hidden;
      transition: all -1s;
      transform: translateX(-40rem);
      width: 30rem;
      z-index: 170;
     
  
  
    }
    


      
    
    .sidebar.open {
      transform: translateX(0);
    
    }  


    .lista-sidebar{

      display: -webkit-box;
      justify-content: space-between;
      justify-items: center;
      margin-left: 2.5rem;
      height: 3rem;
      margin-bottom: 1rem;
      font-size: 1.8rem;
      color: white;
      font-weight: bold;
      font-family: "Raleway",sans-serif;
      font-display: swap;


  }
  .lista-sidebar-signin{
    justify-content: space-between;
    justify-items: center;
    height: 3rem;
    margin-bottom: 1rem;
    font-size: 1.8rem;
    color: white;
    font-weight: bold;
    font-family: "Raleway",sans-serif;
    font-display: swap;

  }
  .lista-sidebar-signin-web{
    display: -webkit-box;
    justify-content: space-between;
    justify-items: center;
    margin-left: 2.5rem;
    height: 3rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    color: white;
    font-weight: bold;
    font-family: "Raleway",sans-serif;
    font-display: swap;
  }
  .lista{
    margin-top: 2rem;
  }

  a:active{
    color:orange !important;
  }
  a:hover {
    color:rgb(122, 182, 11) !important;
  }
  
.placeorder-action{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.placeorder-action13{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
}
.boton-canjear-container{
  display: flex;
    justify-content: center;
   

}
  .cart-items {
    display: flex;
    flex-direction: row !important;
    width: 100% !important;
    list-style-type: none !important;
  
  }





/***HOME**/

.img-auspiciantes{
  width: 10rem !important;
  border-radius: 1rem;  
  
}
.logo-menu{
  width: 13rem !important;
}
.centrar-logom{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}
.slider2{
  margin-top:2rem;
  margin-bottom:30rem;
 
}
.formaciones {
  padding-bottom: 13rem;
}
.aus-jugadorid{
  width: 8rem;
  margin-right: 0.7rem;
  border-radius: 1rem; 
  
}
@media (max-width:700px){
  .aus-jugadorid{
    margin-right: 1rem;   
    margin-left: 1rem  
  }

  
}
.cuadro {
  
 
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: baseline;

}
.club {
  width: 5rem;
  height: 6rem;

}
.cuadro-verde {
  background-color: #174D03;
  padding: 1rem;
  margin: 1rem;
  box-shadow: 0 0 15px 0 #f8f9fa40;
  border-radius: 0 0px 80px 80px;

}
@media (min-width:550px){
  .cuadro-verde {
    margin-left: 6rem;
    margin-right: 6rem;
  }
  .slider2{
    margin-top:2rem;
    margin-bottom:1rem;
   
  }
  .formaciones {
    padding-bottom: 4rem;
}
.logo-menu{
  width: 20rem !important;
}
}
@media (min-width:1000px){
  .cuadro-verde {
    margin-left: 20rem;
    margin-right: 20rem;
  }
}
@media (min-width:1200px){
  .cuadro-verde {
    margin-left: 25rem;
    margin-right: 25rem;
  }
}
/*****TUTORIALES HOME******/
/* .app-video{
margin-top: 2rem;
margin-bottom: 5rem;
} */
.video-contenidos{  
  height: 57rem;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.video-container{
  margin-left: 1.2rem;
}
.calificaciones-video{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.react-player__preview{
  width: 100%;
    height: 100%;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    padding: 2rem !important;
    display: flex;
    align-items: center;
    justify-content: center;
}


@media (min-width:800px){
  .app-video {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: 20rem;
    margin-right: 20rem;
  }

}



/***CONTACTO****/
.leaflet-container {
  max-width: 100%;
  height: 40rem;
  margin-bottom: 5rem;
}
.container-azul{
  background-color: rgba(255, 255, 255, 0.384);
  padding-top: 1rem;
  padding-bottom: 5rem;
  width: 100%;
  box-shadow: 0 0 15px 0 #f8f9fa40;
}
.informes{
  margin: 1rem;
  margin-top: 0rem;

}
.container-azul-informes{
  padding: 1rem;
  width: 100%;
  
}
.container-azul317{
  background-color: rgba(255, 255, 255, 0.384);
  padding-top: 5rem;
  padding-bottom: 5rem;
  box-shadow: 0 0 15px 0 #f8f9fa40;
}
.container-azul3{
  padding-top: 7rem;
  padding-bottom: 2rem;
  width: 100%;
  box-shadow: 0 0 0 0 #f8f9fa40;
  background-color: #13141f;

}
.container-azul2{
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
margin-bottom: 5rem;
 
}
.container-azul233{
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 2rem;

 
}
.container-azul223{
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    bottom: 70px;
 
}
.redes-contacto {
  width: 6rem;
  height: 6rem;
  margin-top: 10rem;
}
.redes-contacto3 {
  width: 3rem;
  height: 3rem;
}
.redes-cont{
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  margin-bottom: 10rem;
}
.redes-cont-id{
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
}
.redes-cont3{
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;

}
.redes-cont22 {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  margin-bottom: 10rem;
}
.redes-cont500 {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}
/* .graficos{
  margin-top: 40rem;
} */

.cab-verde{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px 00px 0 0;
  height: 6rem;
  background-color: #174D03;
  padding: 3rem;
  width: 30rem;
  margin-bottom: -2rem;
  z-index: 30;
  box-shadow: 0 0 15px 0 #f8f9fa40;
  border-radius: 1rem 1rem 0rem 0rem;
}
.cab-verde-jugadorid {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px 00px 0 0;
  height: 5rem;
  background-color: #174D03;
  padding: 2rem;
  width: 30rem;
  margin-bottom: -2rem;
  z-index: 30;
  box-shadow: 0 0 15px 0 #f8f9fa40;
  border-radius: 1rem 1rem 0rem 0rem;
}
.cab-verde304{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px 00px 0 0;
  height: 6rem;
  background-color: #174D03;
  padding: 3rem;
  z-index: 30;
  box-shadow: 0 0 15px 0 #f8f9fa40;
  border-radius: 1rem 1rem 0rem 0rem;
}
.cab-verde3{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px 00px 0 0;
  height: 6rem;
  background-color: #174D03;
  padding: 3rem;
  width: 30rem;
  margin-bottom: -4rem;
  z-index: 30;
  box-shadow: 0 0 15px 0 #f8f9fa40;
  border-radius: 1rem 1rem 0rem 0rem;
}
.radio {
  width: 20px;
  height: 20px;
  position: relative;
  top: 6px;
}
.google-container{
  width: 100%;
  height: 40rem;
  margin-bottom: 1rem;
  margin-left: 1rem;
}

@media (min-width:700px){
  .container-gr{
    display: flex;
    align-items: center;
    justify-content: center;
    
  }

.container-azul2{
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: initial;
    width: 80%;
    bottom: 200px;
    margin-bottom: 0.5rem;
 
}
.informes{
  margin: 2rem;
  margin-right: 4rem;
  margin-left: 4rem;

}
.container-azul-informes{
  padding: 2rem;
  padding-bottom: 5rem;
  width: 100%;

}

.container-azul3{
  border-top: 1px solid #ebeef12e!important;
}
.graficos{
  margin-top: -1rem;
}
.container-azul223{
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position:initial;
    width: 100%;
    bottom: 70px;
 
}
.container-azul233{
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: initial;
    width: 40%;
    margin-top: 4rem;
    margin-bottom: 10rem;

 
}
}

@media (min-width:700px){
  .leaflet-container{

    width: 32rem;
    height: 30rem;
  }
  .texto-contacto{
    margin-bottom: 10rem;
  }
  .google-container{
  margin-left: 1rem;
  }
}

.container-login-form{
  display: flex;
  flex-direction: column;

}
.contacto-home{
  display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
}
.datos-forms-c{
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
}


 .boton-contacto{
   display: flex;
   align-items: center;
 }
 @media (min-width:700px){
  .contacto-home{
    display: flex;
    justify-content: space-evenly;
      flex-direction: row;
      align-items: flex-start;
  }

 }

/***LOGIN****/

.link {
  color: white;
  text-decoration:underline ;
  font-size: 1.5rem;
}
.login-olvidaste{
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
}
.login-olvidaste2{
  
  margin-top: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.error{
  margin: 1.5rem;
  color: white;
  font-size: 1.5rem;
  background-color: black;
  padding: 0.6rem;
  box-shadow: 5px 5px 5px black;
}
.container-contraseña{
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-login{
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* align-items: center; */
}
.datos-forms-1{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;


}
.fecha-nacimiento {
    display: flex;
    justify-content: center;
    /* flex-direction: row; */
    margin-left: 0.5rem;
}

.input-fecha{
  width: 25% !important;
  margin-left: 1.5rem;
  height: 4rem;
  padding: 20px;
  margin-right: 0.5rem;
  border: #fff;
  /* border-radius: 10px; */
  margin-bottom: 20px;
  font-family: "Raleway",sans-serif;
  font-display: swap;
}
.input-tel-se{
  width: 40% !important;
  height: 4rem;
  padding: 0px !important;
  margin-right: 0.5rem;
  border: #fff;
  /* border-radius: 10px; */
  margin-bottom: 20px;
  font-family: "Raleway",sans-serif;
  font-display: swap;
}
.doc-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}
.sexo-container{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  

}
.sexo-input{
  margin-right: 1rem;
  margin-left: 1rem;
  width: 24px;
  height: 24px;

}
.centrar-botones-r{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.centrar-botones-r2{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}


.container-recuperar-c{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.row{
display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-right: 0px !important;
    margin-left: 0px !important; 
}

@media (min-width:860px){ 
.input-fecha{
  width: 13rem;
  height: 4rem;
  padding: 20px;
  margin-right: 0.5rem;
  border: #fff;
  border-radius: 10px;
  margin-bottom: 20px;
  font-family: "Raleway",sans-serif;
  font-display: swap;

}
.fecha-nacimiento {
  display: flex;
  justify-content: flex-start;
  /* flex-direction: row; */
  margin-left: 0.5rem;
}
.comentarios{
  padding: 25px 25px 25px 25px;
  border: white;
  border-radius: 20px;
  margin-bottom: 20px;
  width: 40rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;

}

}
.cuerpo-login{
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width:760px){ 
  .cuerpo-g-l{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cuerpo-login{
    background-color: rgba(255, 255, 255, 0.384);
    padding-top: 5rem;
    padding-bottom: 5rem;
    width: 45rem;
    box-shadow: 0 0 15px 0 #f8f9fa40;
    margin-top: 5rem;
    margin-bottom: 15rem;
  }
  .contra{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /* .c-row-l{
    justify-content: flex-end !important;
  } */

  }




/***MI CUENTA*/
.container-cuenta{
  background-color: white;
  margin: 2rem;
}
.container-cuenta13{
 
  background-color: rgba(255, 255, 255, 0.384);
  margin: 2rem;
  box-shadow: 0 0 10px 0 rgb(175 172 172 / 50%);
  padding: 1rem;
}
.container-puntos{
  background-color: rgb(235, 235, 131);
  margin: 2rem;
  display: flex;
  align-items: center;
  border-radius: 1rem;
  
}
.td{
  font-size: 1.5rem;
}
@media (min-width:760px){
 
.container-micuenta{
display: flex;
justify-content: center;
margin-top: 5rem;
margin-bottom: 10rem;
}
.cuerpo-cu{
  display: flex;
  justify-content: center; 
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;
  margin-bottom: 10rem;
}
.container-cuenta{
width: 50rem;
}
.container-cuenta13{
  width: 40rem;
}
.colum-cuenta{
  width: 10rem;
}
.container-puntos{
  width: 30rem;
}
.cuadro-fidelidad{
  margin-top: 4rem;
}
}

/* Mis pedidos/APARTADO RESUMEN***/

.votaciones {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media (min-width:700px){
  .votaciones-cu {
   margin-top: 5rem;
   margin-bottom: 15rem;
  }
}
.lista-con-backg{
  
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: row;
  margin: 1rem;
  padding: 1rem;
}
.compras{

  padding-top: 4rem;
}
.orden{
  display: flex;
  align-items: baseline;
  justify-content: center;
  flex-direction: column;
}
.sorteos-img{
  width: 8rem;
}



/***NOT FOUND***/
.link-notfound{
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 2rem;
  margin-top: 2rem;
  margin-right: 5rem;
}
.container-notf{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 2rem;
  margin-top: 15rem;
}
.img-notfound{
  width: 20rem;
  height: auto;
}

  /*********SPINNER*********/
.sk-circle {
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative;
}
.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.sk-circle .sk-child:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color:orange;
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
          animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}
.sk-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
      -ms-transform: rotate(30deg);
          transform: rotate(30deg); }
.sk-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
      -ms-transform: rotate(60deg);
          transform: rotate(60deg); }
.sk-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg); }
.sk-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
      -ms-transform: rotate(120deg);
          transform: rotate(120deg); }
.sk-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
      -ms-transform: rotate(150deg);
          transform: rotate(150deg); }
.sk-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg); }
.sk-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
      -ms-transform: rotate(210deg);
          transform: rotate(210deg); }
.sk-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
      -ms-transform: rotate(240deg);
          transform: rotate(240deg); }
.sk-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
          transform: rotate(270deg); }
.sk-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
      -ms-transform: rotate(300deg);
          transform: rotate(300deg); }
.sk-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
      -ms-transform: rotate(330deg);
          transform: rotate(330deg); }
.sk-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s; }
.sk-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
          animation-delay: -1s; }
.sk-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s; }
.sk-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s; }
.sk-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s; }
.sk-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s; }
.sk-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s; }
.sk-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s; }
.sk-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s; }
.sk-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s; }
.sk-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s; }

@-webkit-keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  } 40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  } 40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}



ul.list-style-none li {
  list-style: none;
}
.jugadorid {
  width: 70px;
  height: 70px;
  background-position: top center;
  background-size: 40px 40px;
  margin: 0 auto;
  position: relative;
  z-index: 3;
  border-radius: 50px;
}
.camiseta {
  width: 45px;
  height: 45px;
  background-position: top center;
  background-size: 40px 40px;
  margin: 0 auto;
  position: relative;
  z-index: 3;
  border-radius: 30px;
  border: 1px solid #bbb;
}


/*SWAL*/
.swal-icon--success__hide-corners{
  background-color: #13141f !important;
}
.swal-icon--success__ring{
  border:4px solid hsl(100deg 74% 54%) !important
}
.swal-icon--success__line{
  background-color :#b1e494 !important
}
.swal-modal{
  background-color:#13141f !important ;
}
.swal-title{
  color: white;
}
.swal-text{
  color: white; 
}
.swal-button{
  background-color:#174D03
}
.swal-icon--success:after, .swal-icon--success:before{
  background-color:#13141f !important ;
}

.camiseta:hover, .iconos-r-primero:hover, .iconos-redondos:hover {
  transform: scale(1.2);
}
/*TABS*/
.tabs-container{
  display: flex;
  justify-content: center;
}
.rwt__tablist:not([aria-orientation="vertical"]) .rwt__tab[aria-selected="true"]:after {

  border-bottom: 3px solid green !important;
}
.hr{
border: 0;
height: 0;
border-top: 1px solid #1a1919;
}
.hr2{
  border: 0;
  height: 0;
  border-top: 1px solid #dacfcf;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  }


    .cfff{
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-evenly;
     
 
  }
  .preg{
    font-family: 'Nunito', sans-serif;
    font-display: swap;
    font-weight: bold;
    margin-left: 1rem;
    margin-bottom: 1rem !important;
    margin-top: 2rem !important;
    text-align: initial;
    color:  white;
    letter-spacing: 0.5rem;
    font-size: 2rem !important;
  }
  @media (min-width:760px) {
  .preg{
    font-family: 'Nunito', sans-serif;
    font-display: swap;
    font-weight: bold;
    margin-left: 1rem;
    margin-bottom: 1rem !important;
    margin-top: 4rem !important;
    text-align: initial;
    color:  white;
    letter-spacing: 0.5rem;
    font-size: 3rem !important;
  }
}
@media (min-width:1060px) {
  .preg{
    font-size: 4rem !important;
  }
}

.opacarImg {
  filter: brightness(0.3);
}
.opacarTitle {
  font-weight: bold;
  color: white;
  background-color: #16af7c !important;
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 0.4rem;
  padding-top: 0.4rem;
  border-radius: 1rem;
  height: 2.5rem;
  margin-top: -0.5rem;
  box-shadow: 0 0 10px 0 #1a1a1b !important;
}
.opacarCursor{
  cursor: default;
}
.nombreju {
  font-family: 'Chakra Petch', sans-serif;
  font-weight: bold;
  color: white;
  background-color: #000115;
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 0.4rem;
  padding-top: 0.4rem;
  border-radius: 1rem;
  height: 2.5rem;
  margin-top: -0.5rem;
  box-shadow: 0 0 10px 0 #4351ea;
}
.nombre-jugadorid{
  font-family: 'Chakra Petch', sans-serif;
  font-size: 1.6rem;
  color: white;
  border-radius: 2rem;
  box-shadow: 0 0 15px 0 rgb(255 255 255 / 94%);
  background: #312f2f;
  padding: 0.8rem;
  border-radius: 1rem;
}

.jugadorid-nume {
  font-family: 'Chakra Petch', sans-serif;
  font-size: 1.4rem;
  color: white;
  margin-left: 0.8rem;
  margin-bottom: 0rem;
}
.glass{
  background: rgb(32 73 126 / 36%);
  box-shadow: 0 8px 32px 0 rgb(31 38 135 / 55%);
  backdrop-filter: blur( 0.0px );
  -webkit-backdrop-filter: blur( 0.0px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  padding: 0 1rem 1rem 1rem;
}
.carousel .thumbs {
  display: none !important;
}
.carousel .slide .legend {
  display: none !important;
}
@media (min-width:800) {
.slideimg {
  width: 90% !important;
  vertical-align: top;
  border: 0;
  height: 15rem !important;
}
.carousel .slide .img {
  width: 90% !important;
  vertical-align: top;
  border: 0;
  height: 15rem !important;
}
}
.slick-arrow {
  display: none !important;
}

.form-control {
  font-size: 1.6rem !important;
  height: calc(2.5em + .75rem + 2px) !important;
  color: black;
  
}
.modal {
  font-size: 12px;
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}
.tab {
  display: flex;
  align-items: baseline;
  justify-content: center;
}
.requisitos{
  background-color: rgb(116, 109, 109);
  padding: 0.5rem;
  margin-bottom: 3rem;
}
@media (min-width:700px){
  .requisitos{
    background-color: rgb(39, 37, 37);

  }
}
.botonCancelar{
  font-size: calc(16px + 6 * ((10vw - 320px) / 680));

    width: 15rem;
    height: 3.5rem;
    font-family: 'Roboto',sans-serif;
    font-weight: bold;
    background-color: transparent;
    color: white;
    border: solid 2px #174D03;
    margin-top:2rem;
    margin-right: 2rem;

}
.container-azul-todos{
  background-color: rgba(255, 255, 255, 0.384);
  padding-top: 1rem;
  padding-bottom: 5rem;
  width: 100%;
  box-shadow: 0 0 15px 0 #f8f9fa40;
}
.submenu-nav{
  background-color: rgb(12, 226, 66);
}
.cal-informes2{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #020202;
  padding: 1rem;
  border-radius: 0rem 5rem 0rem 5rem;
  box-shadow: 0 8px 32px 0 rgb(0 0 0 / 86%);
}
.cal-informes{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #020202;
  padding: 1rem;
  border-radius: 0rem 5rem 0rem 5rem;
  box-shadow: 0 8px 32px 0 rgb(0 0 0 / 86%);
}
.cal-informes3{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #020202;
  padding: 1rem;
  border-radius: 0rem 5rem 0rem 5rem;
  box-shadow: 0 8px 32px 0 rgb(0 0 0 / 86%);
}
.cal-informes4{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #020202;
  padding: 1rem;
  border-radius: 0rem 5rem 0rem 5rem;
  box-shadow: 0 8px 32px 0 rgb(0 0 0 / 86%);
}
.cal-informes5{
  background-color: #020202;
  padding: 1rem;
  border-radius: 0rem 5rem 0rem 5rem;
  box-shadow: 0 8px 32px 0 rgb(0 0 0 / 86%);
}
.totales-informes{
  display: flex;
  justify-content: space-around;
  border: solid white;
  margin-bottom: 1rem;
  border-radius: 1rem;

  
}

.time{
  background-color:#000000 ;
  padding: 1rem;
  border-radius: 0rem 0rem 3rem 3rem;
  font-size: 3rem !important;
  margin-left: 1rem;
  margin-right: 1rem;
  color:red;
  font-family: 'Nunito', sans-serif;
  box-shadow: 0 0 15px 0 #f8f9fa40;

}
.time-div{
  margin-top: -2rem;
  
}
